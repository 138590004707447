<template>
  <div>
    <NavBarComponent></NavBarComponent>
    <div class="nosotros-text flex items-center justify-center">
      <h1>{{ textoh1 }}</h1>
    </div>
  </div>
</template>

<script>
import NavBarComponent from "@/components/NavBarComponent/Index.vue";
export default {
  components: {
    NavBarComponent,
  },
  props: ["textoh1"],
};
</script>

<style>
.h-72 {
  height: 72vh;
}
</style>