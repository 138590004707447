<template>
  <div class="" v-if="pages.length > 0">
    <div class="bgdist bg-center" :style="`background-image: url(${page.background_image})`">
      <HeaderComponent :textoh1="'QUIERES SER DISTRIBUIDOR'"></HeaderComponent>
    </div>
    <div class="container mx-auto px-8 h-2/4 py-16">
      <p class="mb-4 title-nosotros">{{page.title}}</p>
      <p class="text-nosotros mb-8" v-html="page.description">
      </p>
    </div>
    <div class="bg-gray">
      <div class="flex justify-center pt-12">
        <span class="title-distribuidores">NUESTROS DISTRIBUIDORES</span>
      </div>
      <div class="flex justify-center items-center pt-4 flextogrid">
        <span class="text-ciudad">SELECCIONA LA CIUDAD</span>
        <div class="px-8">
          <template>
            <!-- <div class="select-">
              <vs-select placeholder="Select" v-model="value">
                <vs-option label="Caracas" value="1"> CARACAS </vs-option>
                <vs-option label="Miranda" value="2"> MIRANDA </vs-option>
                <vs-option label="Tucupita" value="3"> TUCUPITA </vs-option>
                <vs-option disabled label="Apure" value="4"> APURE </vs-option>
                <vs-option label="Guanare" value="5"> GUANARE </vs-option>
                <vs-option label="Nueva Esparta" value="6">
                  NUEVA ESPARTA
                </vs-option>
                <vs-option label="La Guaira" value="7"> LA GUAIRA </vs-option>
              </vs-select>
            </div> -->
          </template>
        </div>
      </div>
      <div
        class="flex container mx-auto px-2 pt-10 justify-center items-center flexreverse"
      >
        <div class="w-full mx-4 flex justify-center">
          <img class="img-maps" src="../../../assets/images/maps.svg" alt="" />
        </div>
        <div class="w-3/4 mx-4 bg-white rounded-lg overflow-auto scroll-style">
          <div class="ml-16 my-8">
            <p class="name-dist">INVERSIONES C.A</p>
            <p class="data-dist">
              Av. El pqraiso, qta. Meridiano, calle 3, piso 4, Baruta.
            </p>
            <p class="data-dist">0414 627 92 92 / 0414 627 92 92</p>
          </div>
          <div class="ml-16 my-8">
            <p class="name-dist">INVERSIONES C.A</p>
            <p class="data-dist">
              Av. El pqraiso, qta. Meridiano, calle 3, piso 4, Baruta.
            </p>
            <p class="data-dist">0414 627 92 92 / 0414 627 92 92</p>
          </div>
          <div class="ml-16 my-8">
            <p class="name-dist">INVERSIONES C.A</p>
            <p class="data-dist">
              Av. El pqraiso, qta. Meridiano, calle 3, piso 4, Baruta.
            </p>
            <p class="data-dist">0414 627 92 92 / 0414 627 92 92</p>
          </div>
          <div class="ml-16 my-8">
            <p class="name-dist">INVERSIONES C.A</p>
            <p class="data-dist">
              Av. El pqraiso, qta. Meridiano, calle 3, piso 4, Baruta.
            </p>
            <p class="data-dist">0414 627 92 92 / 0414 627 92 92</p>
          </div>
          <div class="ml-16 my-8">
            <p class="name-dist">INVERSIONES C.A</p>
            <p class="data-dist">
              Av. El pqraiso, qta. Meridiano, calle 3, piso 4, Baruta.
            </p>
            <p class="data-dist">0414 627 92 92 / 0414 627 92 92</p>
          </div>
          <div class="ml-16 my-8">
            <p class="name-dist">INVERSIONES C.A</p>
            <p class="data-dist">
              Av. El pqraiso, qta. Meridiano, calle 3, piso 4, Baruta.
            </p>
            <p class="data-dist">0414 627 92 92 / 0414 627 92 92</p>
          </div>
        </div>
      </div>
    </div>
    <Footer class="footer-solid py-10"></Footer>
  </div>
</template>

<script>
import HeaderComponent from "@/components/Header/Index.vue";
import Footer from "@/components/Footer/Index.vue";
export default {
  components: {
    HeaderComponent,
    Footer
  },
  data() {
    return {
      pages: [],
      page: {},
    };
  },
  mounted() {
    this.getPages();
  },
  methods: {
    getPages() {
      const loading = this.$vs.loading({
        color: "#0276FF",
      });
      this.$store.dispatch("AdminModule/getPages").then((response) => {
        this.pages = response;
        this.page = this.pages.find((item) => item.page === "distributor");
        loading.close();
      });
    },
  },
};
</script>

<style scoped>
.bg-gray {
  background-color: #f4f4f4;
  height: 75vh;
  width: 100%;
}

.bgdist {
  width: 100%;
  height: 72vh;
}

.simple {
  background-color: white;
}

.scroll-style {
  height: 45vh;
}

.img-maps {
  height: 45vh;
}

@media only screen and (max-width: 639px) {
  .flextogrid {
    display: grid;
    text-align: center;
  }
  .flextoblock {
    display: block;
  }
  .flexreverse {
    display: flex;
    flex-direction: column-reverse;
    padding: 20px 0px;
  }
  .ml-16.my-8 {
    margin: 20px 15px;
  }
  .scroll-style {
    margin: 0px 50px;
  }
  .img-maps {
    height: 20vh;
    margin: 20px 15px;
  }
  .bg-gray {
    height: 100vh;
  }
}
</style>
